import moment from "moment";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as resultActions from "../../../actions/establishmentSelfAssessmentResultActions";
import "./self-assessment-result.scss";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import Papper from "../../../components/Papper/Papper";
import Certificate from "../../../components/Certificate/Certificate";
import Button from "../../../components/Button/Button";
import DetailedReport from "./DetailedReport";
import DropdownAction from "../../../components/DropdownActions/DropdownAction";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import { PdfReport } from "../../../../../shared-components/my-scope/pages/pdf-report";
import {
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_FAST_TRACK,
  ASSESSMENT_TYPE_RE_ASSESSMENT,
  AUDIT_STATUS_SHOWAUDITRESULT,
  AUDIT_STATUS_SHOWAUDITRESULTANDREAUDITT,
  STATUS_SUBMITTED,
  AUDIT_STATUS_UNDERAUDITING,
  AUDIT_STATUS_REAUDIT,
  AUDIT_STATUS_COMPLETED,
  AUDIT_STATUS_UNDERGRACPERIOD,
  AUDIT_STATUS_UNDERINSPECTION,
} from "../../../common/constants/assessmentStatuses";
import Loader from "../../../components/Loader/Loader";
import ResultCard from "../../Components/ResultCard/ResultCard";
import {
  FAST_TRACK,
  RE_ASSESSMENT,
} from "../../../common/constants/paymentStatuses";
import * as fastTrackActions from "../../../actions/FastTrackActions";
import FastTrackComponent from "./FastTrackComponent";
import Lottie from "react-lottie";
import * as animationLoader from "../../../../public/animation/loading.json";
import { CERTIFICATE_MESSAGE } from "../../../common/constants/certificateStatus";
import { Link } from "react-router-dom";
// import Button from "../../../components/Button/Button";

export class SelfAssessmentResult extends React.Component {
  constructor() {
    super();
    this.state = {
      auditingResult: null,
      isLoadingAuditResult: true,
      isLoadingDetailedReport: true,
      isLoadingDuration: true,
      result: null,
      duration: null,
      isFastTrackAuthorized: false,
      isLoadingAuthorization: false,
      actions: [],
      hasActions: false,
    };
    this.getResultColor = this.getResultColor.bind(this);
    this.getPenaltyColor = this.getPenaltyColor.bind(this);
    this.getClosingDaysColor = this.getClosingDaysColor.bind(this);
    this.getScoreColor = this.getScoreColor.bind(this);
    this.onDownloadResult = this.onDownloadResult.bind(this);
    this.redirectToDetailedReportPayment =
      this.redirectToDetailedReportPayment.bind(this);
    this.fetchEstablishmentFastTrackResult =
      this.fetchEstablishmentFastTrackResult.bind(this);
    this.redirectToDetailedReportPayment =
      this.redirectToDetailedReportPayment.bind(this);
    this.redirectToFastTrackPayment =
      this.redirectToFastTrackPayment.bind(this);
  }
  componentDidMount() {
    const {
      fetchSelfAssessmentResult,
      fetchEstablishmentSelfAssessmentDuration,
      match,
    } = this.props;

    const { periodId, assessmentId, establishmentId, assessmentVersion } =
      match.params;

    if (periodId && assessmentId && establishmentId && assessmentVersion) {
      fetchSelfAssessmentResult(
        periodId,
        assessmentId,
        establishmentId,
        assessmentVersion
      ).then((result) => {
        this.setState({ isLoadingDetailedReport: false, result });
      });
      fetchEstablishmentSelfAssessmentDuration(
        periodId,
        assessmentId,
        establishmentId,
        assessmentVersion
      )
        .then((duration) => {
          if (
            duration.audit_status === AUDIT_STATUS_COMPLETED ||
            duration.audit_status === AUDIT_STATUS_UNDERGRACPERIOD ||
            duration.audit_status === AUDIT_STATUS_UNDERINSPECTION
          ) {
            this.fetchEstablishmentFastTrackResult(
              duration,
              periodId,
              assessmentId,
              establishmentId,
              assessmentVersion
            );
          } else
            this.setState({
              isLoadingDuration: false,
              isLoadingAuditResult: false,
              duration,
            });
        })
        .catch((error) => {
          this.setState({
            isLoadingDuration: false,
            isLoadingAuditResult: false,
          });
        });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { duration } = this.state;
    const { duration: prevDuration } = prevState;
    const canGoToFastTrackPayment =
      duration &&
      duration.state === STATUS_SUBMITTED &&
      duration.is_open_reassessment &&
      duration.assessment_type !== ASSESSMENT_TYPE_FAST_TRACK;
    if (prevDuration !== duration && canGoToFastTrackPayment) {
      this.redirectToFastTrackPayment();
    }
  }

  // Method to update actions and determine if there are any
  setActions = (filteredActions) => {
    this.setState({
      actions: filteredActions,
      hasActions: filteredActions.length > 0,
    });
  };

  fetchEstablishmentFastTrackResult(
    duration,
    periodId,
    assessmentId,
    establishmentId,
    assessmentVersion
  ) {
    const { fetchEstablishmentFastTrackResult } = this.props;

    fetchEstablishmentFastTrackResult(
      periodId,
      assessmentId,
      establishmentId,
      assessmentVersion
    )
      .then((auditingResult) => {
        this.setState({
          isLoadingDuration: false,
          isLoadingAuditResult: false,
          duration,
          auditingResult,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingDuration: false,
          isLoadingAuditResult: false,
          duration,
        });
      });
  }
  getResultColor() {
    const { result } = this.state;

    if (result.closing_days > 0 || result.final_result < 30) {
      return "danger";
    }

    return result.final_result > 75 ? "success" : "warning";
  }

  getPenaltyColor() {
    const { result } = this.state;

    if (result.closing_days > 0) {
      return "danger";
    }

    if (result.max_penalty <= 0) {
      return "success";
    }

    return result.final_result >= 30 ? "warning" : "danger";
  }

  getClosingDaysColor() {
    return this.state.result.closing_days > 0 ? "danger" : "success";
  }

  getScoreColor(score) {
    if (score < 30) {
      return "danger";
    }

    return score > 75 ? "success" : "warning";
  }

  redirectToDetailedReportPayment(withoutResult = true) {
    const { match, history } = this.props;
    const { periodId, assessmentId, establishmentId, assessmentVersion } =
      match.params;

    history.push(
      `/establishmentAssessmentPayment/${periodId}/${assessmentId}/${establishmentId}/${assessmentVersion}/true/Detailed-Report`
    );
  }

  onDownloadResult() {
    const { result, duration, auditingResult } = this.state;

    if (
      duration.audit_status === AUDIT_STATUS_COMPLETED ||
      duration.audit_status === AUDIT_STATUS_UNDERGRACPERIOD ||
      duration.audit_status === AUDIT_STATUS_UNDERINSPECTION
    ) {
      const auditReport = auditingResult?.audit_report;

      PdfReport(
        {
          assessment: auditReport.assessment,
          auditing_score: auditReport.auditing_score,
          final_result: result.final_result,
          certificate_name: auditReport.certificate_name,
          max_penalty: auditReport.max_penalty,
          closing_days: auditReport.closing_days,
        },
        {
          establishment: {
            ...auditReport.establishment,
          },
          request_created_by: auditReport.request_created_by.String,
          request_created_by_email: auditReport.request_created_by_email.String,
          request_created_by_mobile:
            auditReport.request_created_by_mobile.String,
          period_name: auditReport.period_name,
          start_date: auditReport.start_date.String,
          end_date: auditReport.end_date.String,
          auditors: auditReport.auditors,
          committee_arabic_name: auditReport.committee_arabic_name,
          supervisor_name: auditReport.supervisor_name,
        },
        true,
        duration.assessment_type === ASSESSMENT_TYPE_BASIC
      );
    }
    // else
    // PdfReport(
    //   {
    //     assessment: result.assessment,
    //     final_result: result.final_result,
    //     max_penalty: result.max_penalty,
    //     closing_days: result.closing_days,
    //   },
    //   {
    //     establishment: {
    //       ...result.establishment,
    //       nitaqat_color: result.establishment.nitaq_color,
    //     },
    //     request_created_by: result.user.name_ar.String,
    //     request_created_by_email: result.user.email.String,
    //     request_created_by_mobile: result.user.phone_number.String,
    //     period_name: result.period_details.name,
    //     start_date: result.period_details.start_date,
    //     end_date: result.period_details.end_date,
    //   },
    //   true
    // );
  }
  redirectToFastTrackPayment() {
    const { establishmentProfile, fetchFastTrackServiceAuthorization } =
      this.props;
    const { duration } = this.state;
    const effectivePeriodId = duration.period_id || duration.id;

    this.setState({
      isLoadingAuthorization: true,
    });
    fetchFastTrackServiceAuthorization(
      establishmentProfile.id,
      effectivePeriodId,
      FAST_TRACK
    )
      .then((isFastTrackAuthorized) => {
        this.setState({
          isFastTrackAuthorized: isFastTrackAuthorized,
          isLoadingAuthorization: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoadingAuthorization: false,
        });
      });
  }
  render() {
    const { establishmentProfile, isCertificateVisible, history } = this.props;
    const {
      auditingResult,
      isLoadingAuditResult,
      isLoadingDetailedReport,
      isLoadingDuration,
      result,
      duration,
      isFastTrackAuthorized,
      isLoadingAuthorization,
      hasActions,
    } = this.state;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationLoader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const withoutResult = false;
    const SAstartMoment =
      result &&
      result.timestamps.first_opened_at.Valid &&
      moment(result.timestamps.first_opened_at.String);
    const SAendMoment =
      result &&
      result.timestamps.submitted_at.Valid &&
      moment(result.timestamps.submitted_at.String);

    const minutesSpentOnSa =
      SAendMoment &&
      SAstartMoment &&
      //moment.duration(SAendMoment.diff(SAstartMoment)).asHours();
      moment.utc(
        moment.duration(SAendMoment.diff(SAstartMoment)).asMilliseconds()
      );

    const timeSpentOnSA =
      Math.round(minutesSpentOnSa / (60 * 60 * 24 * 1000)) +
      " يوم " +
      (Math.floor((minutesSpentOnSa / 1000 / 60 / 60) % 24) + " ساعة ") +
      (Math.floor((minutesSpentOnSa / 1000 / 60) % 60) + " دقيقة ");

    const isAuditResultStandardCategories =
      duration &&
      (duration.audit_status === AUDIT_STATUS_COMPLETED ||
        duration.audit_status === AUDIT_STATUS_UNDERGRACPERIOD ||
        duration.audit_status === AUDIT_STATUS_UNDERINSPECTION) &&
      auditingResult;
    const isShowAuditingResult =
      duration &&
      (duration.audit_status === AUDIT_STATUS_COMPLETED ||
        duration.audit_status === AUDIT_STATUS_UNDERGRACPERIOD ||
        duration.audit_status === AUDIT_STATUS_UNDERINSPECTION);
    const isReAssessment =
      duration && duration.assessment_type === ASSESSMENT_TYPE_RE_ASSESSMENT;
    const isUnderAuditing =
      duration && duration.audit_status === AUDIT_STATUS_UNDERAUDITING;
    // const ReAudit =
    //  duration &&
    // (duration.audit_status === AUDIT_STATUS_REAUDIT);
    const isCompleteAndNotCertified =
      duration &&
      duration.assessment_type === ASSESSMENT_TYPE_FAST_TRACK &&
      isShowAuditingResult &&
      result.certificate_status === "NotCertified";

    return (
      <React.Fragment>
        <div className="doNotPrint">
          <PageWrapper withBreadcrumbs>
            {isLoadingAuditResult ||
            isLoadingDetailedReport ||
            isLoadingDuration ||
            isLoadingAuthorization ? (
              <div className="loader-div">
                {/* <Loader /> */}
                <Lottie options={defaultOptions} height={160} width={160} />
                <p className="loader-text">يرجى الانتظار..</p>
              </div>
            ) : (
              <div className="self-assessment-result self-assessment-result__page-margins">
                {duration.assessment_type === ASSESSMENT_TYPE_FAST_TRACK &&
                  isShowAuditingResult && (
                    <div className="message-wrapper">
                      <Text
                        textType="roman"
                        fontSize={"font-18"}
                        mobileFontSize={"font-16-mobile"}
                        className={"message-text "}
                        Tag={"span"}
                      >
                        {CERTIFICATE_MESSAGE[result.certificate_status]}{" "}
                      </Text>
                      {result.certificate_status === "NotCertified" && (
                        <Text
                          textType="bold"
                          fontSize={"font-17"}
                          mobileFontSize={"font-15-mobile"}
                          className={"message-text "}
                          Tag={"span"}
                        >
                          <Link
                            className="message-link "
                            to={`/establishmentAssessmentPayment/${
                              duration.id || duration.period_id
                            }/${
                              duration.assessment_id || duration.assessment?.id
                            }/${establishmentProfile.id}/${
                              duration.assessment_version
                            }/${withoutResult}/${RE_ASSESSMENT}/${
                              duration.period_assessment_id
                            }`}
                          >
                            خدمة إعادة التقييم.
                          </Link>
                        </Text>
                      )}
                      {/* {
                result.certificate_status === "NotCertified"
                result.certificate_status === "Certified"
                }  */}
                    </div>
                  )}
                <div className="self-assessment-result-header">
                  {
                    <div className="self-assessment-result-header__text">
                      <Text
                        textType="bold"
                        fontSize={"font-24"}
                        mobileFontSize={"font-20-mobile"}
                        className="self-assessment-result-header__title "
                      >
                        نتيجة التقييم
                      </Text>
                      {isUnderAuditing && (
                        <Text
                          textType="roman"
                          fontSize={"font-18"}
                          mobileFontSize={"font-16-mobile"}
                          className="self-assessment-result-header__text"
                        >
                          ستظهر لك النتيجة النهائية بعد التدقيق من قبل المختصين
                        </Text>
                      )}
                    </div>
                  }
                  <div
                    //  className="self-assessment-result-header-wrapper"
                    className={` self-assessment-result-header-wrapper ${
                      result?.is_detailed_report &&
                      isShowAuditingResult &&
                      this.state.hasActions
                        ? "center-align"
                        : "left-align"
                    }`}
                  >
                    {result?.is_detailed_report && isShowAuditingResult && (
                      <Button
                        fontSize={"font-16"}
                        textType="bold"
                        onClick={this.onDownloadResult}
                        text="تحميل النتيجة"
                        id="show-result-button"
                        customTextSize="16px"
                      />
                    )}

                    {duration && (
                      <>
                        <DropdownAction
                          duration={duration}
                          profileId={establishmentProfile.id}
                          label="الإجراءات"
                          textAlign="center"
                          actions={this.state.actions}
                          setActions={this.setActions}
                          ignoreActions={
                            isCompleteAndNotCertified ? [8, 3, 4, 5, 9] : [8]
                          }
                          // showGracePeriod
                          id="procedures-button"
                          iconLeft={"img/arrow-drodDown.svg"}
                          trackType={
                            duration.assessment_type === ASSESSMENT_TYPE_BASIC
                              ? "basic"
                              : duration.assessment_type ===
                                ASSESSMENT_TYPE_FAST_TRACK
                              ? "special"
                              : null
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="self-assessment-result-cards-wrapper">
                  <section className="self-assessment-summary">
                    {result?.is_detailed_report && isShowAuditingResult && (
                      <ResultCard
                        finalResult={result.final_result}
                        closingDays={result.closing_days}
                        results={result.standard_categories_scores}
                        isBeforeAudit
                      />
                    )}
                    {(!result?.is_detailed_report ||
                      (result?.is_detailed_report &&
                        !isShowAuditingResult)) && (
                      <section className="self-assessment-data">
                        <Papper withBorder>
                          <Text
                            className="self-assessment-data-title"
                            fontSize={"font-18"}
                            mobileFontSize="font-18-mobile"
                            textType="bold"
                          >
                            بيانات الفترة التقييمية
                          </Text>
                          <div className="self-assessment-data-row">
                            <div className="self-assessment-data-cell">
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="roman"
                                className="self-assessment-data-cell__header"
                              >
                                الفترة التقييمية
                              </Text>
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="bold"
                                className="self-assessment-data-cell__text"
                              >
                                {result.period_details.name}
                              </Text>
                            </div>

                            <div className="self-assessment-data-cell">
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="roman"
                                className="self-assessment-data-cell__header"
                              >
                                تاريخ بداية الفترة التقييمية
                              </Text>
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="bold"
                                className="self-assessment-data-cell__text"
                              >
                                {moment(
                                  result.period_details.start_date
                                ).format("YYYY/MM/DD")}
                              </Text>
                            </div>

                            <div className="self-assessment-data-cell">
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="roman"
                                className="self-assessment-data-cell__header"
                              >
                                تاريخ نهاية الفترة التقييمية
                              </Text>
                              <Text
                                fontSize={"font-18"}
                                mobileFontSize="font-16-mobile"
                                textType="bold"
                                className="self-assessment-data-cell__text"
                              >
                                {moment(result.period_details.end_date).format(
                                  "YYYY/MM/DD"
                                )}
                              </Text>
                            </div>
                          </div>

                          <div className="self-assessment-data-row">
                            <div className="self-assessment-data-cell">
                              {timeSpentOnSA && (
                                <div>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="roman"
                                    className="self-assessment-data-cell__header"
                                  >
                                    المدة المستغرقة
                                  </Text>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="bold"
                                    className="self-assessment-data-cell__text"
                                  >
                                    {timeSpentOnSA}
                                  </Text>
                                </div>
                              )}
                            </div>

                            <div className="self-assessment-data-cell">
                              {SAstartMoment && (
                                <div>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="roman"
                                    className="self-assessment-data-cell__header"
                                  >
                                    تاريخ بداية التقييم الذاتي
                                  </Text>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="bold"
                                    className="self-assessment-data-cell__text"
                                  >
                                    {SAstartMoment.format("YYYY/MM/DD")}
                                  </Text>
                                </div>
                              )}
                            </div>

                            <div className="self-assessment-data-cell">
                              {SAendMoment && (
                                <div>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="roman"
                                    className="self-assessment-data-cell__header"
                                  >
                                    تاريخ نهاية التقييم الذاتي
                                  </Text>
                                  <Text
                                    fontSize={"font-18"}
                                    mobileFontSize="font-16-mobile"
                                    textType="bold"
                                    className="self-assessment-data-cell__text"
                                  >
                                    {SAendMoment.format("YYYY/MM/DD")}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                        </Papper>
                      </section>
                    )}

                    {/* <div className="self-assessment-card-group">
                    <div className="self-assessment-card self-assessment-card--wide">
                      <div
                        className={`self-assessment-text--${this.getClosingDaysColor()}`}
                      >
                        <Text
                          Tag="span"
                          isBold
                          textType="p1"
                          className="self-assessment-score"
                        >
                          {result.closing_days}
                        </Text>
                        <Text
                          Tag="span"
                          textType="p3"
                          className="self-assessment-subtext"
                        >
                          أيام
                        </Text>
                      </div>
                      <Text textType="p4" className="self-assessment-label">
                        عدد أيام الإغلاق المتوقعة في حال عدم التصحيح
                      </Text>
                    </div>
                    <div className="self-assessment-card self-assessment-card--wide">
                      <div
                        className={`self-assessment-text--${this.getPenaltyColor()}`}
                      >
                        <Text
                          Tag="span"
                          isBold
                          textType="p1"
                          className="self-assessment-score"
                        >
                          {result.max_penalty}
                        </Text>
                        <Text
                          Tag="span"
                          textType="p3"
                          className="self-assessment-subtext"
                        >
                          ريال سعودي
                        </Text>
                      </div>
                      <Text textType="p4" className="self-assessment-label">
                        مجموع الغرامات المتوقعة في حال عدم التصحيح
                      </Text>
                    </div>
                  </div> */}
                    {/* <div className="self-assessment-card">
                    <Text
                      isBold
                      textType="p1"
                      className={`self-assessment-percent self-assessment-text--${this.getResultColor()}`}
                    >
                      %{Math.round(result.final_result)}
                    </Text>
                    <Text
                      textType="p4"
                      className="self-assessment-label self-assessment-label--centered"
                    >
                      النسبة الكلية
                    </Text>
                  </div>
                  {result &&
                    result.standard_categories_scores.map((item) => (
                      <div className="self-assessment-card" key={item.name}>
                        <Text
                          isBold
                          textType="p1"
                          className={`self-assessment-percent self-assessment-text--${this.getScoreColor(
                            item.score
                          )}`}
                        >
                          %{Math.round(item.score)}
                        </Text>
                        <Text
                          textType="p4"
                          className="self-assessment-label self-assessment-label--centered"
                        >
                          {item.name}
                        </Text>
                      </div> */}
                    {/* ))} */}
                  </section>
                  {
                    <section className="self-assessment-summary">
                      {/* <Text
                        Tag="div"
                        isBold
                        textType="h5"
                        className="self-assessment-summary__title"
                      >
                        بعد التدقيق
                      </Text> */}
                      <ResultCard
                        closingDays={result.closing_days}
                        finalResult={
                          isShowAuditingResult
                            ? auditingResult?.audit_report?.auditing_score
                            : ""
                        }
                        results={
                          isShowAuditingResult
                            ? auditingResult?.audit_report
                                .standard_categories_scores
                            : []
                        }
                        isShowResults={isShowAuditingResult}
                      />
                    </section>
                  }
                </div>
                {result?.is_detailed_report && isShowAuditingResult && (
                  <section className="self-assessment-data">
                    <Text
                      className="self-assessment-data-header"
                      isBold
                      textType="h7"
                    >
                      تفاصيل الفترة التقييمية
                    </Text>
                    <Papper withBorder>
                      <div className="self-assessment-data-row">
                        <div className="self-assessment-data-cell">
                          <Text
                            textType="p5"
                            className="self-assessment-data-cell__header"
                          >
                            الفترة التقييمية
                          </Text>
                          <Text
                            textType="p5"
                            isBold
                            className="self-assessment-data-cell__text"
                          >
                            {result.period_details.name}
                          </Text>
                        </div>

                        <div className="self-assessment-data-cell">
                          <Text
                            textType="p5"
                            className="self-assessment-data-cell__header"
                          >
                            تاريخ بداية الفترة التقييمية
                          </Text>
                          <Text
                            textType="p5"
                            isBold
                            className="self-assessment-data-cell__text"
                          >
                            {moment(result.period_details.start_date).format(
                              "YYYY/MM/DD"
                            )}
                          </Text>
                        </div>

                        <div className="self-assessment-data-cell">
                          <Text
                            textType="p5"
                            className="self-assessment-data-cell__header"
                          >
                            تاريخ نهاية الفترة التقييمية
                          </Text>
                          <Text
                            textType="p5"
                            isBold
                            className="self-assessment-data-cell__text"
                          >
                            {moment(result.period_details.end_date).format(
                              "YYYY/MM/DD"
                            )}
                          </Text>
                        </div>
                      </div>

                      <div className="self-assessment-data-row">
                        <div className="self-assessment-data-cell">
                          {timeSpentOnSA && (
                            <div>
                              <Text
                                textType="p5"
                                className="self-assessment-data-cell__header"
                              >
                                المدة المستغرقة
                              </Text>
                              <Text
                                textType="p5"
                                isBold
                                className="self-assessment-data-cell__text"
                              >
                                {timeSpentOnSA}
                              </Text>
                            </div>
                          )}
                        </div>
                        <div className="self-assessment-data-cell">
                          {SAstartMoment && (
                            <div>
                              <Text
                                textType="p5"
                                className="self-assessment-data-cell__header"
                              >
                                تاريخ بداية التقييم الذاتي
                              </Text>
                              <Text
                                textType="p5"
                                isBold
                                className="self-assessment-data-cell__text"
                              >
                                {SAstartMoment.format("YYYY/MM/DD")}
                              </Text>
                            </div>
                          )}
                        </div>

                        <div className="self-assessment-data-cell">
                          {SAendMoment && (
                            <div>
                              <Text
                                textType="p5"
                                className="self-assessment-data-cell__header"
                              >
                                تاريخ نهاية التقييم الذاتي
                              </Text>
                              <Text
                                textType="p5"
                                isBold
                                className="self-assessment-data-cell__text"
                              >
                                {SAendMoment.format("YYYY/MM/DD")}
                              </Text>
                            </div>
                          )}
                        </div>
                      </div>
                    </Papper>
                  </section>
                )}
                {result?.is_detailed_report && duration.assessment_type && (
                  <Fragment>
                    <Text
                      className="self-assessment-data-header"
                      textType="bold"
                      fontSize="font-18"
                    >
                      التقرير المفصل
                    </Text>

                    <DetailedReport
                      standardCategories={
                        isAuditResultStandardCategories
                          ? auditingResult?.audit_report?.assessment
                              .standard_categories
                          : result.assessment.standard_categories
                      }
                      assessmentType={duration.assessment_type}
                      isShowAuditResult={isShowAuditingResult}
                    />
                  </Fragment>
                )}
                {isFastTrackAuthorized && (
                  <FastTrackComponent
                    data={{
                      title: "خدمة المسار المميز",
                      description:
                        "يمكنك من خلال هذه الخدمة الحصول على شهادة الامتثال والتي يمكن إعتبارها كإثبات معتمد من قِبل وزارة الموارد البشرية والتمنية الاجتماعية بأن المنشأة ملتزمة بالمعايير المحددة",
                    }}
                    buttonText={"اطلب الخدمة"}
                    onClick={() => {
                      const effectivePeriodId =
                        duration.period_id || duration.id;
                      const effectiveAssessmentId =
                        duration.assessment_id || duration.assessment?.id;
                      history.push(
                        `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${establishmentProfile.id}/${duration.assessment_version}/${withoutResult}/Fast-track/${duration.period_assessment_id}`
                      );
                    }}
                  />
                )}
              </div>
            )}
          </PageWrapper>
        </div>

        {isCertificateVisible && <Certificate />}
      </React.Fragment>
    );
  }
}

SelfAssessmentResult.propTypes = {
  fetchSelfAssessmentResult: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,

  establishmentProfile: PropTypes.object,
  isCertificateVisible: PropTypes.bool.isRequired,
};

SelfAssessmentResult.defaultProps = {
  match: { params: {} },
};

const mapStateToProps = (state) => ({
  isLoading:
    state.establishmentSelfAssessmentResult
      .establishmentSelfAssessmentResultFetching,
  establishmentProfile: state.establishment.establishmentProfile,
  isCertificateVisible: state.certificate.isVisible,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelfAssessmentResult: (
    periodId,
    assessmentId,
    establishmentId,
    assessmentVersion
  ) =>
    dispatch(
      resultActions.fetchEstablishmentSelfAssessmentResult(
        periodId,
        assessmentId,
        establishmentId,
        assessmentVersion
      )
    ),
  fetchEstablishmentSelfAssessmentDuration: (
    periodId,
    assessmentId,
    establishmentId,
    assessmentVersion
  ) =>
    dispatch(
      resultActions.fetchEstablishmentSelfAssessmentDuration(
        periodId,
        assessmentId,
        establishmentId,
        assessmentVersion
      )
    ),
  fetchEstablishmentFastTrackResult: (
    periodId,
    assessmentId,
    establishmentId,
    assessmentVersion
  ) =>
    dispatch(
      resultActions.fetchEstablishmentFastTrackResult(
        periodId,
        assessmentId,
        establishmentId,
        assessmentVersion
      )
    ),
  fetchFastTrackServiceAuthorization: (
    establishmentId,
    durationId,
    serviceType
  ) =>
    dispatch(
      fastTrackActions.fetchFastTrackServiceAuthorization(
        establishmentId,
        durationId,
        serviceType
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelfAssessmentResult));
